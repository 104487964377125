import gql from "graphql-tag";
import cliToolsMutations from "../mutations/cliTools";
import { getFilteredListQuery, getListQuery, getIdSelectorQuery } from "../queries";
import { TransactionUpdateProcessType } from "../../../../common/enums/TransactionUpdateProcessType.enum";
import misc from "@/utils/misc";
import { acceptedFamilyDefinitionsList } from "@/portal/pages/admin/constants";
import { CliToolsGroup } from "@/common/enums";

export default {
  ...cliToolsMutations,
  getOptions: gql`
    query cliOptions($cmdName: String!) {
      cliOptions(cmdName: $cmdName)
    }
  `,
  cmds: {
    compareDate: {
      label: "Compare Date b/w two sheets",
      group: CliToolsGroup.OTHER,
      noFile: true,
      schema: {
        fields: [
          {
            model: "sheetNo",
            type: "select",
            values: () => [],
            label: "Select Sheet",
            placeholder: "Pick the sheet to import records from",
            selectOptions: {
              noneSelectedText: "Pick the sheet to import records from",
            },
          },
          {
            model: "dobColumn",
            type: "select",
            values: [],
            label: "Select column with date of birth",
            placeholder: "Pick the column with date",
            selectOptions: {
              noneSelectedText: "Pick the column with date",
            },
          },
          {
            label: "Upload excel file created for portal setup",
            type: "upload",
            model: "files",
            multiple: true,
            rawOutput: true,
            accept: "*",
            required: true,
            async onChanged(model, schema, event, instance) {
              model.files.portalSetupFile = await event.target.files[0];
            },
          },
        ],
      },
      transformArgs: (argsModel) => argsModel,
    },
    onboardOrg: {
      label: "Onboard org/import users",
      group: CliToolsGroup.ONBOARDING,
      doc: "https://www.notion.so/novabenefits/CLI-Tool-Docs-70fe26c7dd56486b907444a2927f58ca?pvs=4",
      errorTypes: [
        "Dependents limit exceeded",
        "Incorrect relation type (File format issue)",
        "DOB missing (File format issue)",
        "Incorrect Gender (File format issue)",
        "Dependent DOB missing",
        "Incorrect Employee age",
        "Incorrect Parent/Parent in law age",
        "Incorrect Spouse age",
        "Employee and spouse age difference exceeded",
        "Incorrect child age",
        "Duplicate email id",
        "Duplicate Spouse",
        "Dependent without an employee",
        "Duplicate parent",
        "Both parent and in laws are present",
        "Parents/Parent in laws have same gender",
        "LGBTQ user",
        "Similar Name",
      ],
      validationSchema: {
        fields: [
          {
            type: "input",
            inputType: "number",
            label: "Minimum employee age",
            model: "employeeAgeMin",
            styleClasses: "d-inline-flex col-md-6 flex-column px-0 pr-3",
          },
          {
            type: "input",
            inputType: "number",
            label: "Maximum employee age",
            model: "employeeAgeMax",
            styleClasses: "d-inline-flex col-md-6 flex-column px-0",
          },
          {
            type: "input",
            inputType: "number",
            label: "Minimum parent age",
            model: "parentAgeMin",
            styleClasses: "d-inline-flex col-md-6 flex-column px-0 pr-3",
          },
          {
            type: "input",
            inputType: "number",
            label: "Maximum parent age",
            model: "parentAgeMax",
            styleClasses: "d-inline-flex col-md-6 flex-column px-0",
          },
          {
            type: "input",
            inputType: "number",
            label: "Spouse minimum age",
            model: "spouseAgeMin",
            styleClasses: "d-inline-flex col-md-6 flex-column px-0 pr-3",
          },
          {
            type: "input",
            inputType: "number",
            label: "Spouse maximum age",
            model: "spouseAgeMax",
            styleClasses: "d-inline-flex col-md-6 flex-column px-0",
          },
          {
            type: "input",
            inputType: "number",
            label: "Maximum difference in employee and spouse age",
            model: "maxAgeDifference",
            min: 0,
          },
          {
            type: "input",
            inputType: "number",
            label: "Minimum child age",
            model: "childAgeMin",
            min: 0,
            styleClasses: "d-inline-flex col-md-6 flex-column px-0 pr-3",
          },
          {
            type: "input",
            inputType: "number",
            label: "Maximum child age",
            model: "childAgeMax",
            min: 0,
            styleClasses: "d-inline-flex col-md-6 flex-column px-0",
          },
          {
            type: "checkbox",
            label: "Enable LGBTQ Check",
            model: "lgbtqCheck",
          },
          {
            type: "checkbox",
            label: "Enable Duplicate Name Check",
            model: "duplicateNameCheck",
          },
        ],
      },
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            // dont fetch all data for cli tools
            gqlQuery: getFilteredListQuery("Org", "orgFragmentForCliTools"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "orgEntity",
            type: "id-selector",
            queryName: "orgEntities",
            gqlQuery: getIdSelectorQuery("OrgEntity"),
            queryVariables: () => ({ filter: {} }),
            label: "Org Entity",
            visible: false,
            required: true,
          },
          {
            model: "sendOnboardingInvites",
            type: "checkbox",
            label: "Send onboarding invite email to these users",
            fieldClasses: ["mb-3"],
          },
          {
            type: "label",
            hint: "Email invites if selected will be sent to all users in the file even if they have completed their onboarding flow",
          },
          {
            type: "label",
            label:
              "Note - This tool would only create new users and won't update the data of existing users" +
              "<br>It can however be used to link benefits/policies to existing users and dependents at any time",
          },
        ],
      },
      transformArgs: (argsModel) => {
        argsModel.policies = argsModel.policies?.map(({ policy }) => {
          return {
            policy: {
              id: policy.id,
              type: policy.type,
              name: policy.name,
              insurer: {
                id: policy.insurer?.id,
                name: policy.insurer?.name,
              },
            },
          };
        });
        argsModel.benefits = argsModel.benefits?.map(({ benefit }) => {
          return {
            benefit: {
              id: benefit.id,
              type: benefit.type,
              name: benefit.name,
            },
          };
        });
        argsModel.orgEntity = argsModel.orgEntity || {};
        return argsModel;
      },
    },
    setupOrgAndPolicies: {
      label: "Process Closing Slip",
      group: CliToolsGroup.BENEFITS,
      schema: {
        fields: [
          {
            model: "policyClaimContent",
            type: "id-selector",
            gqlQuery: getListQuery("StrapiContent"),
            queryVariables: () => ({
              filter: {
                collectionType: "Benefit",
              },
            }),
            label: "Policy Claim Content (from strapi)",
            nodesFromData: misc.getOptionsMapperForStrapi("strapiContents"),
            required: true,
            validator: "required",
          },
        ],
      },
      transformArgs: (argsModel) => argsModel,
    },
    benefitsBulkAddition: {
      label: "Benefits Bulk Addition",
      group: CliToolsGroup.BENEFITS,
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "benefits",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit", "benefitFragmentForCliTools"),
            queryVariables: () => ({
              filter: {
                isPolicy: false,
                hideExpired: true,
              },
            }),
            label: "Benefits",
            multiple: true,
            required: true,
          },
          {
            type: "label",
            label: "Note - This tool will link selected benefits to all the employees of selected Org",
            visible: true,
          },
          {
            model: "linkSelectedUsers",
            label: "Link benefits to selected users only?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, benefits, ...args }) => ({
        ...args,
        orgId: org?.id,
        benefits: benefits.map((benefit) => {
          return { id: benefit.id, name: benefit.name, status: benefit.status };
        }),
      }),
    },
    importECards: {
      label: "Import ecards zip",
      group: CliToolsGroup.ECARD,
      schema: {
        fields: [
          {
            model: "insurerFormat",
            type: "select",
            label: "Insurer",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit", "benefitFragmentForCliTools"),
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
        ],
      },
      getOptionsFromApi: true,
      transformArgs: ({ org, policy, ...args }) => ({
        ...args,
        orgId: org?.id,
        policyId: policy?.id,
      }),
    },
    fetchECards: {
      label: "Fetch E Cards",
      group: CliToolsGroup.ECARD,
      schema: {
        fields: [
          {
            model: "tpa",
            type: "select",
            label: "TPA / Insurer",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit", "benefitFragmentForCliTools"),
            getSubTitle: (u) => u.name,
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
        ],
      },
      noFile: true,
      getOptionsFromApi: true,
      transformArgs: ({ org, policy, ...args }) => ({
        ...args,
        orgId: org?.id,
        orgName: org?.name,
        policyId: policy?.id,
        policyNumber: policy?.meta?.policyNumber,
      }),
    },
    claimsBulkUpload: {
      label: "Bulk Upload Claims",
      group: CliToolsGroup.CLAIMS,
      schema: {
        fields: [
          {
            model: "tpa",
            type: "select",
            label: "TPA / Insurer",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit", "benefitFragmentForCliTools"),
            queryVariables: () => ({
              filter: {
                isPolicy: false,
              },
            }),
            label: "Select Benefit",
            required: true,
          },
          {
            model: "mockData",
            type: "text-area",
            inputType: "text",
            placeholder: "Add mock data array here if needed.",
            rows: 5,
          },
        ],
      },
      getOptionsFromApi: true,
      transformArgs: (argsModel) => ({
        tpa: argsModel.tpa,
        policyId: argsModel.policy?.id,
        orgId: argsModel.org?.id,
        mockData: argsModel.mockData,
      }),
    },
    bulkUpdateDatabaseTable: {
      label: "Bulk Update Database Table",
      group: CliToolsGroup.OTHER,
      schema: {
        fields: [
          {
            model: "dbTable",
            type: "select",
            label: "Database Table Name",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "fixRelationMapping",
            type: "checkbox",
            label: "Fix Relation Mapping",
            default: false,
            fieldClasses: ["mb-0"],
          },
          {
            type: "label",
            label:
              "(Additional columns to be added: Custom → crmRelation, Custom → crmPatientName, Custom → crmGender)",
            styleClasses: "mb-0 mt-n2",
          },
          {
            model: "dryRun",
            type: "checkbox",
            label: "Dry Run (does not write to the database)",
            default: false,
            fieldClasses: ["mb-0"],
          },
          {
            model: "file",
            type: "file",
            label: "File dump",
            required: true,
          },
        ],
      },
      getOptionsFromApi: true,
      transformArgs: (argsModel) => argsModel,
    },
    exportChanges: {
      label: "Export user changes",
      group: CliToolsGroup.USER_DETAILS,
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "uploadToCloud",
            type: "select",
            label: "Upload xlsx to google cloud?",
            values: [true, false],
          },
        ],
      },
      transformArgs: ({ org, batch, ...args }) => ({
        ...args,
        orgId: org?.id,
        batchId: batch?.id,
      }),
    },
    nukeCache: {
      label: "Delete cached content",
      group: CliToolsGroup.OTHER,
      schema: {
        fields: [
          {
            model: "cacheKeyToBeNuked",
            type: "select",
            label: "Cache category to be deleted",
            values: [],
          },
        ],
      },
      noFile: true,
      getOptionsFromApi: true,
      transformArgs: (argsModel) => argsModel,
    },
    tpaOngoingClaimsSync: {
      label: "Update Ongoing Claims via TPA API",
      group: CliToolsGroup.CLAIMS,
      noFile: true,
      transformArgs: (argsModel) => argsModel,
    },
    createClaimsZohodeskTicket: {
      label: "Create Zohodesk Ticket For Claims",
      group: CliToolsGroup.CLAIMS,
      noFile: true,
      transformArgs: (argsModel) => argsModel,
    },
    removeUserBenefits: {
      label: "Remove User Benefits",
      group: CliToolsGroup.BENEFITS,
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "benefit",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit", "benefitFragmentForCliTools"),
            queryVariables: () => ({
              filter: {
                isPolicy: false,
              },
            }),
            label: "Select Benefit",
            required: true,
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, ...args }) => ({ ...args, orgId: org.id }),
    },
    sendIntroToNovaEmails: {
      label: "Send Nova Intro Email",
      group: CliToolsGroup.ONBOARDING,
      hint: "Sends intro to nova email to org users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "orgEntity",
            type: "id-selector",
            queryName: "orgEntities",
            gqlQuery: getIdSelectorQuery("OrgEntity"),
            queryVariables: () => ({ filter: {} }),
            label: "Org Entity",
            visible: false,
            required: true,
          },
          {
            model: "sendEmailOn",
            label: "Send email on",
            type: "select-cards",
            cardsData: [
              {
                label: "Mailmodo",
                card_value: "mailmodo",
              },
              {
                label: "SES",
                card_value: "ses",
              },
            ],
          },
          {
            model: "campaignId",
            type: "input",
            inputType: "text",
            label: "Campaign ID (optional)",
            visible: true,
          },
          {
            model: "emailTemplateFile",
            type: "upload",
            label: "Pug Email Template (optional):",
            accept: ".pug",
            visible: false,
            styleClasses: "d-flex",
            attributes: { class: "ml-2 font-sm" },
          },
          {
            type: "label",
            label: "This tool will send emails to all the employees of selected Org",
            visible: true,
          },
          {
            model: "sendToSelectedUsers",
            label: "Send email to selected users only?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, orgEntity, campaignId, ...args }) => ({
        ...args,
        orgId: org?.id,
        orgEntityId: orgEntity?.id,
        campaignId: campaignId || "",
      }),
    },
    sendBenefitDetailsEmails: {
      label: "Send Policy Parameters as Email",
      group: CliToolsGroup.BENEFITS,
      hint: "Sends emails to all users with an active policy whose status is not deleted",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "orgEntity",
            type: "id-selector",
            queryName: "orgEntities",
            gqlQuery: getIdSelectorQuery("OrgEntity"),
            queryVariables: () => ({ filter: {} }),
            label: "Org Entity",
            visible: false,
            required: true,
          },
          {
            model: "sendEmailOn",
            label: "Send email on",
            type: "select-cards",
            cardsData: [
              {
                label: "Mailmodo",
                card_value: "mailmodo",
              },
              {
                label: "SES",
                card_value: "ses",
              },
            ],
          },
          {
            model: "campaignId",
            type: "input",
            inputType: "text",
            label: "Campaign ID (optional)",
            visible: true,
          },
          {
            model: "emailTemplateFile",
            type: "upload",
            label: "Pug Email Template (optional):",
            accept: ".pug",
            visible: false,
            styleClasses: "d-flex",
            attributes: { class: "ml-2 font-sm" },
          },
          {
            model: "gmcBlockId",
            type: "input",
            inputType: "text",
            label: "Mail GMC/PPC blockId (optional)",
          },
          {
            model: "gpaBlockId",
            type: "input",
            inputType: "text",
            label: "Mail GPA/GTL blockId (optional)",
          },
          {
            type: "label",
            label: "Note - This tool will send emails to all the employees of selected Org",
            visible: true,
          },
          {
            model: "sendToSelectedUsers",
            label: "Send email to selected users only?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, orgEntity, policy, campaignId, ...args }) => ({
        ...args,
        orgId: org?.id,
        orgEntityId: orgEntity?.id,
        policyId: policy?.id,
        campaignId: campaignId || "",
      }),
    },
    sendPortalActivatedEmails: {
      label: "Send Portal Activated Email",
      group: CliToolsGroup.ONBOARDING,
      hint: "Sends Portal activated email to org users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "orgEntity",
            type: "id-selector",
            queryName: "orgEntities",
            gqlQuery: getIdSelectorQuery("OrgEntity"),
            queryVariables: () => ({ filter: {} }),
            label: "Org Entity",
            visible: false,
            required: true,
          },
          {
            model: "sendEmailOn",
            label: "Send email on",
            type: "select-cards",
            cardsData: [
              {
                label: "Mailmodo",
                card_value: "mailmodo",
              },
              {
                label: "SES",
                card_value: "ses",
              },
            ],
          },
          {
            model: "campaignId",
            type: "input",
            inputType: "text",
            label: "Campaign ID (optional)",
            visible: true,
          },
          {
            model: "emailTemplateFile",
            type: "upload",
            label: "Pug Email Template (optional):",
            accept: ".pug",
            visible: false,
            styleClasses: "d-flex",
            attributes: { class: "ml-2 font-sm" },
          },
          {
            type: "label",
            label: "This tool will send emails to all the employees of selected Org",
            visible: true,
          },
          {
            model: "sendToSelectedUsers",
            label: "Send email to selected users only?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, orgEntity, campaignId, ...args }) => ({
        ...args,
        orgId: org?.id,
        orgEntityId: orgEntity?.id,
        campaignId: campaignId || "",
      }),
    },
    sendEcardLinkedEmails: {
      label: "Send E-Card Linked Email",
      group: CliToolsGroup.ECARD,
      hint: "Sends portal E-Card linked email to org users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "orgEntity",
            type: "id-selector",
            queryName: "orgEntities",
            gqlQuery: getIdSelectorQuery("OrgEntity"),
            queryVariables: () => ({ filter: {} }),
            label: "Org Entity",
            visible: false,
            required: true,
          },
          {
            model: "sendEmailOn",
            label: "Send email on",
            type: "select-cards",
            cardsData: [
              {
                label: "Mailmodo",
                card_value: "mailmodo",
              },
              {
                label: "SES",
                card_value: "ses",
              },
            ],
          },
          {
            model: "campaignId",
            type: "input",
            inputType: "text",
            label: "Campaign ID (optional)",
            visible: true,
          },
          {
            model: "emailTemplateFile",
            type: "upload",
            label: "Pug Email Template (optional):",
            accept: ".pug",
            visible: false,
            styleClasses: "d-flex",
            attributes: { class: "ml-2 font-sm" },
          },
          {
            model: "sendEcardAttachment",
            label: "📎 Send ecard pdf as attachment",
            type: "checkbox",
            visible: false,
          },
          {
            type: "label",
            label: "This tool will send emails to all the employees of selected Org",
            visible: true,
          },
          {
            model: "sendToSelectedUsers",
            label: "Send email to selected users only?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, orgEntity, campaignId, ...args }) => ({
        ...args,
        orgId: org?.id,
        orgEntityId: orgEntity?.id,
        campaignId: campaignId || "",
      }),
    },
    sendOnboardingReminderEmails: {
      label: "Send Onboarding Reminder Email",
      group: CliToolsGroup.ONBOARDING,
      hint: "Sends onboarding reminder email to selected org users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "orgEntity",
            type: "id-selector",
            queryName: "orgEntities",
            gqlQuery: getIdSelectorQuery("OrgEntity"),
            queryVariables: () => ({ filter: {} }),
            label: "Org Entity",
            visible: false,
            required: true,
          },
          {
            model: "sendEmailOn",
            label: "Send email on",
            type: "select-cards",
            cardsData: [
              {
                label: "Mailmodo",
                card_value: "mailmodo",
              },
              {
                label: "SES",
                card_value: "ses",
              },
            ],
          },
          {
            model: "campaignId",
            type: "input",
            inputType: "text",
            label: "Campaign ID (optional)",
            visible: true,
          },
          {
            model: "emailTemplateFile",
            type: "upload",
            label: "Pug Email Template (optional):",
            accept: ".pug",
            visible: false,
            styleClasses: "d-flex",
            attributes: { class: "ml-2 font-sm" },
          },
          {
            type: "label",
            label: "This tool will send emails to the employees who have not completed the onboarding flow",
          },
          {
            model: "sendToSelectedUsers",
            label: "Send email to selected users only?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, orgEntity, campaignId, ...args }) => ({
        ...args,
        orgId: org?.id,
        orgEntityId: orgEntity?.id,
        campaignId: campaignId || "",
      }),
    },
    sendBenefitsAvailableEmails: {
      label: "Send Benefits Available Email",
      group: CliToolsGroup.BENEFITS,
      hint: "Sends benefits available email to org users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getFilteredListQuery("Org", "orgFragmentForCliTools"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
            onChanged: (data) => {
              const benefitsModel = data?.benefits;
              benefitsModel?.splice(0, benefitsModel?.length);
            },
          },
          {
            model: "orgEntity",
            type: "id-selector",
            queryName: "orgEntities",
            gqlQuery: getIdSelectorQuery("OrgEntity"),
            queryVariables: () => ({ filter: {} }),
            label: "Org Entity",
            visible: false,
            required: true,
          },
          {
            model: "benefits",
            type: "checklist",
            values: (data) => {
              const benefits = [];
              const orgOrOrgEntityBenefits = Object.keys(data?.orgEntity || {}).length
                ? data?.orgEntity?.benefits
                : data?.org?.benefits;
              orgOrOrgEntityBenefits?.forEach((d) => {
                const benefit = d.node;
                if (!benefit.isPolicy) {
                  benefits.push({
                    name: benefit.name,
                    id: benefit.id,
                  });
                }
              });
              return benefits;
            },
            checklistOptions: {
              name: "name",
              value: "id",
            },
            label: "Benefits",
            required: true,
          },
          {
            model: "campaignId",
            type: "input",
            inputType: "text",
            label: "Campaign ID (optional)",
          },
          {
            type: "label",
            label: "This tool will send emails to all the employees of selected Org",
            visible: true,
          },
          {
            model: "sendToSelectedUsers",
            label: "Send email to selected users only?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, orgEntity, campaignId, ...args }) => ({
        ...args,
        orgId: org?.id,
        orgEntityId: orgEntity?.id,
        campaignId: campaignId || "",
      }),
    },
    orgEntityBulkUpdate: {
      label: "Bulk Update Users Org Entity",
      group: CliToolsGroup.USER_DETAILS,
      hint: "Updates org entity of users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "orgEntity",
            type: "id-selector",
            queryName: "orgEntities",
            gqlQuery: getIdSelectorQuery("OrgEntity"),
            queryVariables: () => ({ filter: {} }),
            visible: false,
            label: "Org Entities",
            required: true,
          },
        ],
      },
      transformArgs: ({ org, orgEntity, file, ...args }) => ({
        ...args,
        orgId: org?.id,
        orgEntityId: orgEntity?.id,
        file,
      }),
    },
    vipBulkUpdate: {
      label: "Bulk Update Users Priority",
      group: CliToolsGroup.USER_DETAILS,
      hint: "Updates priority of users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
        ],
      },
      transformArgs: ({ org, file, ...args }) => ({ ...args, orgId: org?.id, file }),
    },
    syncInsuranceStatus: {
      label: "Bulk Resync User Insurance Status",
      group: CliToolsGroup.USER_DETAILS,
      hint: "Syncs insurance status in bulk for users",
      schema: {
        fields: [],
      },
      transformArgs: ({ file, ...args }) => ({ ...args, file }),
    },
    bulkMapUHID: {
      label: "Bulk Map UHID",
      group: CliToolsGroup.ECARD,
      hint: "Maps UHID to users",
      schema: {
        fields: [
          {
            model: "tpa",
            type: "select",
            label: "TPA / Insurer",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit", "benefitFragmentForCliTools"),
            getSubTitle: (u) => u.name,
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
          {
            model: "file",
            type: "file",
            label: "File dump",
            required: true,
          },
        ],
      },
      getOptionsFromApi: true,
      transformArgs: ({ org, file, policy, ...args }) => ({ ...args, orgId: org?.id, policyId: policy?.id, file }),
    },
    bulkUpdateUserDetails: {
      label: "Bulk Update User and Dependents Details",
      group: CliToolsGroup.USER_DETAILS,
      hint: "Bulk updates details of users and dependents (dependents name mandatory)",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "orgEntity",
            type: "id-selector",
            queryName: "orgEntities",
            gqlQuery: getIdSelectorQuery("OrgEntity"),
            queryVariables: () => ({ filter: {} }),
            label: "Org Entity",
            visible: false,
            required: true,
          },
          {
            model: "customGradeMapping",
            label: "Enable Custom SI and Family Definition Mapping To Users?",
            type: "checkbox",
          },
        ],
      },
      transformArgs: ({ org, file, policy, customGradeMapping, orgEntity, ...args }) => ({
        ...args,
        orgId: org?.id,
        file,
        policyId: policy?.id || null,
        customGradeMapping: customGradeMapping || false,
        orgEntityId: orgEntity?.id || null,
      }),
    },
    bulkInsertTeamAndDesignations: {
      label: "Bulk Insert Teams and Designations",
      group: CliToolsGroup.USER_DETAILS,
      hint: "Bulk inserts teams and designations for team management section",
      schema: {
        fields: [
          {
            model: "type",
            type: "select",
            label: "Select Type",
            values: ["Team", "Designation"],
            fieldClasses: ["form-select"],
            required: true,
            validator: "required",
          },
        ],
      },
      transformArgs: ({ type, file, ...args }) => ({
        ...args,
        type,
        file,
      }),
    },
    bulkUploadClaimsFromCrm: {
      label: "Bulk Upload Claims from CRM to Portal",
      group: CliToolsGroup.CRM,
      transformArgs: (argsModel) => argsModel,
    },
    generateNovaEcards: {
      label: "Generate Nova Branded E-cards",
      group: CliToolsGroup.ECARD,
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit", "benefitFragmentForCliTools"),
            getSubTitle: (u) => u.name,
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
          {
            model: "user",
            type: "id-selector",
            queryName: "users",
            gqlQuery: getIdSelectorQuery("User"),
            label: "Users",
          },
          {
            model: "skipUsersWithEcard",
            type: "checkbox",
            label: "Skip users who already have a Nova E-Card",
            default: false,
            fieldClasses: ["mb-0"],
          },
          {
            model: "sendNotification",
            type: "checkbox",
            label: "Send Email Notifications",
            default: true,
            fieldClasses: ["mb-0"],
          },
          {
            type: "label",
            label: "Selecting this option will send an email to the users, even if they have received one before.",
            styleClasses: "mb-0",
          },
        ],
      },
      noFile: true,
      getOptionsFromApi: true,
      transformArgs: ({ org, policy, user, ...args }) => ({
        ...args,
        orgId: org?.id,
        policyId: policy?.id,
        /** Might need multi-select in the future, this will change if it happens */
        userIds: user?.id ? [user?.id] : null,
      }),
    },
    bulkImportDocFromCrm: {
      label: "Bulk import doc from CRM to Portal",
      group: CliToolsGroup.CRM,
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
          },
          {
            model: "bulkImportCrmDocs",
            label: "Bulk import Docs?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, ...args }) => ({
        ...args,
        orgId: org?.id || "",
      }),
    },
    syncCxEntitiesToCrm: {
      label: "Sync CX mapping to CRM from Portal",
      group: CliToolsGroup.CRM,
      schema: {
        fields: [
          {
            model: "orgs",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organizations",
            hint: "Select the 'Global (Default for all orgs)' org to sync for all ACTIVE orgs",
            multiple: true,
            showSelected: false,
          },
        ],
      },
      noFile: true,
      transformArgs: ({ orgs, ...args }) => ({
        ...args,
        orgIds: orgs.map((org) => org?.id) || [],
      }),
    },
    expireBenefits: {
      label: "Run benefits expiry tool",
      group: CliToolsGroup.BENEFITS,
      schema: {
        fields: [],
      },
      noFile: true,
      transformArgs: ({ ...args }) => ({
        ...args,
      }),
    },
    syncAHCAppointments: {
      label: "Sync AHC Appointments",
      group: CliToolsGroup.OTHER,
      schema: {
        fields: [],
      },
      noFile: true,
      transformArgs: ({ ...args }) => ({
        ...args,
      }),
    },
    claimsCsatBulkUpdate: {
      label: "Bulk Update Claims CSAT",
      group: CliToolsGroup.CLAIMS,
      hint: "Updates CSAT of claims",
      schema: {
        fields: [],
      },
      transformArgs: ({ org, file, ...args }) => ({ ...args, orgId: org?.id, file }),
    },
    updateOrRemoveTransaction: {
      label: "Update/Remove a transaction",
      group: CliToolsGroup.ACCOUNTING,
      noFile: true,
      schema: {
        fields: [
          {
            model: "transactionId",
            type: "input",
            inputType: "uuid",
            label: "Enter Transaction Id (uuid)",
            required: true,
          },
          {
            model: "processType",
            type: "select",
            values: () => [TransactionUpdateProcessType.UPDATE, TransactionUpdateProcessType.REMOVE],
            label: "Update/Remove transaction",
            placeholder: "Pick whether to remove or update the transaction",
            required: true,
          },
          {
            model: "updatedTransactionAmount",
            type: "input",
            inputType: "number",
            label: "Enter new transaction amount",
            visible(model) {
              return model && model.processType == TransactionUpdateProcessType.UPDATE;
            },
          },
        ],
      },
      transformArgs: (argsModel) => argsModel,
    },
    sendBulkEmails: {
      label: "Send Bulk Email",
      group: CliToolsGroup.OTHER,
      doc: process.env.VUE_APP_SEND_BULK_EMAIL_DOC,
      hint: "Sends bulk email to selected users using mailmodo",
      schema: {
        fields: [
          {
            model: "campaignId",
            type: "input",
            inputType: "text",
            label: "Campaign ID",
            visible: true,
          },
          {
            model: "ctaEndpoint",
            type: "input",
            inputType: "text",
            label: "CTA Endpoint",
            visible: true,
          },
        ],
      },
      noFile: false,
      transformArgs: (argsModel) => argsModel,
    },
    fetchPolicyFromCare: {
      label: "Fetch Policy Details from Care Health Insurance",
      group: CliToolsGroup.BENEFITS,
      doc: process.env.VUE_APP_FETCH_CARE_POLICY_DOC,
      hint: "Fetch Policy Details from Care Health Insurance required for automated endorsements",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit", "benefitFragmentForCliTools"),
            getSubTitle: (u) => u.name,
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
        ],
      },
      noFile: true,
      getOptionsFromApi: true,
      transformArgs: ({ org, policy, user, ...args }) => ({
        ...args,
        orgId: org?.id,
        policyId: policy?.id,
      }),
    },
    brDeck: {
      label: "BR Deck File Upload",
      group: CliToolsGroup.OTHER,
      hint: "Upload and Process BR Deck files for an organization's policy",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getIdSelectorQuery("Org"),
            queryVariables: () => ({
              filter: { includeStatuses: ["ACTIVE"] },
            }),
            label: "Organization (Showing only ACTIVE orgs)",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit", "benefitFragmentForCliTools"),
            getSubTitle: (u) => u.name,
            queryVariables: () => ({
              filter: {
                isPolicy: true,
                orgId: null,
              },
            }),
            label: "Policy",
            required: true,
          },
          {
            model: "fileType",
            type: "select",
            values: ["claims_dump", "account_statement", "active_list"],
            label: "Select file type for upload",
          },
          {
            type: "label",
            label: "Please upload the claims files in the specified format",
          },
        ],
      },
      getOptionsFromApi: true,
      transformArgs: ({ org, policy, ...args }) => ({
        ...args,
        orgId: org?.id,
        policyId: policy?.id,
      }),
    },
  },
  cmdsAdminReviewChanges: {
    exportChanges: {
      label: "Export user changes",
      schema: {
        fields: [
          {
            model: "uploadToCloud",
            type: "select",
            label: "Upload xlsx to google cloud?",
            values: [true, false],
          },
        ],
      },
    },
  },
};
