import { getIdSelectorQuery } from "../queries";

const orgPropertyList = [
  "FITNESS_RATES",
  "FEATURE_FLAGS",
  "LAST_BULK_EMAIL_SYNC",
  "LOGIN_OPTIONS",
  "ORG_SEGMENT",
  "RELATIONSHIP_MANAGER_MAPPING",
  "KENKO_ORG_MAPPING",
  "MEDIBUDDY_ORG_ENTITY_ID",
  "MEDIBUDDY_API_ENTITY_ID",
  "XOXO_STORE_API_TOKEN",
  "ORG_ONBOARDING_DEFAULT_ETA",
  "ZOHO_DESK_OAUTH_TOKEN",
  "ZOHO_CRM_OAUTH_TOKEN",
  "PLACEHOLDER_FITNESS_DATA",
  "ORG_ONBOARDING_EMAIL_DETAILS",
  "INSURER_TPA_HANDBOOK_MAPPING",
  "ANALYTICS_DASHBOARD_CONFIGURATIONS",
  "EKINCARE_PWA_ORG_ENTITY_ID",
  "EKINCARE_API_ORG_ENTITY_KEYS",
  "YOURDOST_ORG_ID",
  "MINDPEERS_PRODUCT_CODE",
  "PREFERENCES",
  "BRAND_CUSTOMIZATIONS",
  "CUSTOM_DEPENDENT_VALIDATION_PARAMS",
  "CUSTOM_EMAIL_TEMPLATES",
  "ZOHODESK_CONFIG",
  "EMPLOYEE_ASSESSMENTS",
  "PEOPLE_STRONG_CONFIG",
  "CUSTOM_BANNERS",
];
export default {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        label: "Organization",
      },
      {
        model: "name",
        type: "select",
        label: "Property Name",
        values: orgPropertyList,
        required: true,
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        placeholder: "Organization filter (none)",
        showSelected: false,
      },
      {
        model: "orgEntity",
        type: "id-selector",
        queryName: "orgEntities",
        gqlQuery: getIdSelectorQuery("OrgEntity"),
        placeholder: "Org Entity filter (none)",
        showSelected: false,
      },
      {
        model: "orgPropertyName",
        type: "select",
        label: "Property Name",
        values: orgPropertyList,
        placeholder: "Organization Property",
        showSelected: false,
      },
    ],
  },
};
